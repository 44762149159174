/* index.css */
:root {
  --theme-background: #ffffff;
  --theme-primary: #635BFA;
  --theme-primary-focus: #521afa;
  --theme-secondary: #00D4FF;
  --theme-red: #fe2b2b;
  --theme-orange: #f9ac1c;
  --theme-light-grey: #eeeeee;
  --theme-dark-grey: #aaaaaa;
  --theme-green: #c3f8a0;
  --theme-yellow: #fcedb9;
  --theme-light-blue: #ecfcff;
  --ui-dark: #282829;
  --ui-light: #ffffff;
  --typography-color-light: #ffffff;
  --typography-color-dark: #383838;
  --typography-button: #ffffff;
  --box-shadow: 0 8px 20px 0 #d8d8d8;
  --hover-shadow: 0px 4px 20px #7f78a5;
}

body {
  font-family: Avenir, 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  padding: 0px;
  margin: 0 auto;
  max-height: 100vh;
  overflow: scroll;
}

.shell {
  background-color: var(--theme-background);
  margin: 0 auto;
}

p,
h1,
h2,
h3,
h4,
li {
  color: var(--typography-color-dark);
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin: 0px !important;
}

p,
li {
  font-weight: 300;
  white-space: pre-line !important;
}

h1 {
  font-weight: bolder;
  font-size: 36px;
  text-align: center;
}

h2 {
  font-size: 32px;
  font-weight: bold;
}

h3 {
  font-size: 24px;
  font-weight: bold;
}

h4 {
  font-size: 20px;
  font-weight: bold;
}

h5 {
  font-size: 16px;
  font-weight: bold;
}

li {
  font-size: 16px;
}

td {
  padding: 0.5em;
}

tr {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

tr:hover {
  background-color: var(--theme-light-grey);
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.drawer-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1.2em; */
}

.invoice-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2em;
  border: 1px dashed var(--theme-primary);
}

.right-aligned {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}


/* app.css */
.page-container {
  padding: 0px 3em;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  height: 100vh;
  background-color: var(--theme-background);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0em;
}

.tile-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.tile-wrapper:hover {
  cursor: pointer;
}

.title-wrapper {
  display: flex;
  flex-wrap: row wrap;
  justify-content: flex-start;
  padding: 2em;
}

.base-card {
  background-color: var(--theme-background);
  box-shadow: var(--box-shadow);
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.7em;
  border-radius: 30px;
  border: 2px solid #ccc;
}

.action-card {
  padding: 1em;
  width: 250px;
}

.action-card:hover {
  cursor: pointer;
  transition: all 0.7s ease;
  box-shadow: var(--hover-shadow);
}

.card-grid-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  width: 100%;
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 1em;
  width: 100%;
}

.dropdown-item {
  margin: 0px 1em !important;
}


/* layout.css */
.layout-wrapper {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
}

.drawer-toggle {
  transform: rotate(180deg);
}


/* typography.css */
.drawer-heading {
  font-size: 30px;
  color: #ffffff;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}

.input-label {
  font-size: 0.75rem;
  color: var(--typography-color-dark);
  margin: 0px;
}

.input-error-label {
  color: var(--theme-red);
}